<template>
    <vs-td>
        <vs-chip :color="getOrderStatusColor(data)" class="product-order-status">{{
            getOrderStatusState(data) }}
        </vs-chip>
    </vs-td>
</template>

<script>
    export default {
        name: 'status-row',
        props: ["data"],
        methods: {
            getOrderStatusColor(status) {
                if (status == 0) return 'warning';
                if (status == false) return 'warning';
                if (status == 1) return 'success';
                if (status == true) return 'success';
                return 'primary';
            },
            getOrderStatusState(status) {
                if (status == 0) return 'Unavailable';
                if (status == false) return 'Unavailable';
                if (status == 1) return 'Available';
                if (status == true) return 'Available';
                return 'Unknown';
            },
        }
    };
</script>

<style scoped>

</style>
