<template>
    <vs-td >
        <img :src="data" style="height:70px; width:70px; vertical-align: middle"
             onerror="this.onerror=null;
             this.src='https://uwosh.edu/facilities/wp-content/uploads/sites/105/2018/09/no-photo.png';"
        />
    </vs-td>
</template>

<script>
    export default {
        name: 'image-row',
        props: ["data"]
    };
</script>

<style scoped>

</style>
