<template>
    <div>
        <div>
            <breadcrumb name="Test Drive" title="All" subtitle="List" />

            <div id="data-list-thumb-view" class="data-list-container">
                <vs-row style="width: 55%; margin-left: 1%">
                    <vs-col
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                    >
                        <vs-input
                            type="date"
                            v-model="from_date"
                            class="input"
                            size="medium"
                            label="From Date"
                        />
                    </vs-col>
                    <vs-col
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                    >
                        <vs-input
                            type="date"
                            v-model="to_date"
                            class="input"
                            size="medium"
                            label="To Date"
                        />
                    </vs-col>
                    <vs-col
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-align="center"
                    >
                        <vs-button
                            style="margin-top: 22px"
                            color="success"
                            type="filled"
                            @click="filter"
                        >
                            Filter
                        </vs-button>
                    </vs-col>
                    <vs-col>
                        <vs-tabs v-model="agent_status" :color="colorx">
                            <vs-tab
                                label="All"
                                value="all"
                                @click="colorx = 'rgb(52.9, 80.8, 92.2)'"
                            >
                                <div></div>
                            </vs-tab>
                            <vs-tab
                                label="Pending"
                                value="pending"
                                @click="colorx = 'warning'"
                            >
                                <div></div>
                            </vs-tab>
                            <vs-tab
                                label="In Progress"
                                value="in_progress"
                                @click="colorx = 'primary'"
                            >
                                <div></div>
                            </vs-tab>
                            <vs-tab
                                label="Done"
                                value="done"
                                @click="colorx = 'success'"
                            >
                                <div></div>
                            </vs-tab>
                        </vs-tabs>
                    </vs-col>
                </vs-row>

                <vs-table
                    ref="table"
                    :sst="true"
                    @search="handleSearch"
                    @sort="handleSort"
                    search
                    :data="items"
                >
                    <div
                        slot="header"
                        class="flex flex-wrap-reverse items-center flex-grow justify-between"
                    >
                        <div class="flex flex-wrap-reverse items-center">
                            <vs-button
                                color="primary"
                                type="filled"
                                @click="exportData"
                                >Export Requests</vs-button
                            >
                        </div>

                        <div
                            class="flex flex-wrap-reverse items-center data-list-btn-container"
                        ></div>

                        <!-- ITEMS PER PAGE -->
                        <table-header
                            :current-page="currentPage"
                            :items-per-page="itemsPerPage"
                            :length="items.length"
                            :queried-items="queriedItems"
                            @onChangeItemsPerPage="itemsPerPage = $event"
                        />
                    </div>

                    <template slot="thead">
                        <vs-th sort-key="date">ID</vs-th>

                        <vs-th sort-key="name">Name</vs-th>
                        <vs-th sort-key="mobile">Mobile</vs-th>
                        <vs-th sort-key="branch">Branch Name</vs-th>
                        <vs-th sort-key="brand">Brand</vs-th>
                        <vs-th sort-key="model">Model</vs-th>
                        <vs-th sort-key="date">Date</vs-th>
                        <vs-th sort-key="time">Time</vs-th>
                        <vs-th sort-key="Sent At">Sent At</vs-th>
                        <vs-th sort-key="status">Status</vs-th>
                        <vs-th sort-key="status">Agent</vs-th>

                        <vs-th sort-key="elapsed_time">Elapsed Time</vs-th>
                        <!--            <vs-th sort-key="mobile_active">To</vs-th>-->
                        <vs-th>Action</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <tbody>
                            <vs-tr
                                :data="tr"
                                :key="indextr"
                                v-for="(tr, indextr) in data"
                            >
                                <string-row :data="tr.id" />

                                <string-row :data="tr.name" />
                                <string-row :data="tr.mobile" />
                                <string-row
                                    :data="tr.branch ? tr.branch.name : 'None'"
                                />
                                <string-row
                                    :data="
                                        tr.parent
                                            ? tr.parent.brand_name
                                            : 'None'
                                    "
                                />
                                <string-row
                                    :data="
                                        tr.parent
                                            ? tr.parent.model_name
                                            : 'None'
                                    "
                                />
                                <string-row
                                    :data="tr.date | moment('DD MMMM YYYY')"
                                />
                                <string-row :data="tr.time" />
                                <string-row
                                    :data="
                                        tr.created_at | moment('DD MMMM YYYY')
                                    "
                                />
                                <AgentStatusRow
                                    v-if="tr.agent_status"
                                    :data="
                                        tr.agent_status
                                            .toUpperCase()
                                            .replace('_', ' ')
                                    "
                                />
                                <vs-td v-else>None</vs-td>
                                <string-row
                                    :data="tr.agent ? tr.agent.name : 'None'"
                                />
                                <string-row :data="tr.elapsed_time" />

                                <!--              <string-row :data="tr.time_to"/>-->
                                <actions-row
                                    :show="true"
                                    :editable="false"
                                    :deletable="true"
                                    permissionName="delete-test-drive-requests"
                                    @onShowClicked="
                                        $router.push(
                                            `/show-test-drive-request/${tr.id}`
                                        )
                                    "
                                    @onDeleteClicked="deleteData(tr.id)"
                                />
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
            </div>
            <vs-pagination :total="totalItems" v-model="page" />
        </div>
    </div>
</template>

<script>
import breadcrumb from "../../../components/general/breadcrumb";
import TableHeader from "../../../components/table/table-header";
import StringRow from "../../../components/table/rows/string-row";
import ImageRow from "../../../components/table/rows/image-row";
import StatusRow from "../../../components/table/rows/status-row";
import ActionsRow from "../../../components/table/rows/actions-row";
import TagRow from "../../../components/table/rows/tag-row";
import AddNewButton from "../../../components/form/addnew-button";
import AgentStatusRow from "../../../components/table/rows/agent-status-row";

import HtmlRow from "../../../components/table/rows/html-row";

import io from "socket.io-client";
const socket = io(process.env.VUE_APP_SOCKET_URL);

export default {
    components: {
        HtmlRow,

        ActionsRow,
        StatusRow,
        ImageRow,
        StringRow,
        TableHeader,
        breadcrumb,
        TagRow,
        AddNewButton,
        AgentStatusRow,
    },
    data() {
        return {
            items: [],
            item: {},
            itemsPerPage: 20,
            isMounted: false,
            page: 1,
            totalItems: 0,
            query: "",
            sortKey: null,
            sortActive: null,
            edit: false,
            add: false,
            // Data Sidebar
            addNewDataSidebar: false,
            sidebarData: {},
            from_date: "",
            to_date: "",
            colorx: "dark",
            agent_status: 0,
        };
    },
    computed: {
        currentPage() {
            if (this.isMounted) {
                return this.$refs.table.currentx;
            }
            return 0;
        },
        queriedItems() {
            return this.$refs.table
                ? this.$refs.table.queriedResults.length
                : this.items.length;
        },
    },
    watch: {
        page() {
            this.getData();
        },
        query() {
            this.getData();
        },
        sortKey() {
            this.getData();
        },
        sortActive() {
            this.getData();
        },
        itemsPerPage() {
            this.getData();
        },
        agent_status() {
            this.getData();
        },
    },
    methods: {
        exportData() {
            let queryString = `?query=${this.query}&field=${this.sortKey}&ordering=${this.sortActive}`;
            if (this.from_date.length > 0) {
                queryString += `&from_date=${this.from_date}`;
            }
            if (this.to_date.length > 0) {
                queryString += `&to_date=${this.to_date}`;
            }
            if (this.agent_status > 0) {
                const statuses = {
                    1: "pending",
                    2: "in_progress",
                    3: "done",
                };
                queryString += `&agent_status=${statuses[this.agent_status]}`;
            }
            window.location.href =
                process.env.VUE_APP_ADMIN_URL +
                "/" +
                "test-drives/requests/export" +
                queryString;
        },
        onSuccessOperation() {
            this.getData();
        },
        addNewData() {
            this.add = true;
        },
        deleteData(id) {
            this.$vs.loading();
            this.$httpAdmin
                .delete(`/test-drives/requests/${id}`)
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Successful operation",
                        text: "Request has been deleted successfully",
                        color: "success",
                    });
                    this.getData();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't delete data on the server",
                        color: "danger",
                    });
                });
        },
        editData(data) {
            //this.sidebarData = data;
            //this.toggleDataSidebar(true)
            this.item = data;
            this.edit = true;
        },
        handleSearch(query) {
            this.query = query;
        },
        handleSort(key, active) {
            this.sortKey = key;
            this.sortActive = active === "asc" ? 1 : 0;
        },
        getData() {
            this.$vs.loading();
            let queryString = `per_page=${this.itemsPerPage}&page=${this.page}&query=${this.query}&field=${this.sortKey}&ordering=${this.sortActive}`;
            if (this.from_date.length > 0) {
                queryString += `&from_date=${this.from_date}`;
            }
            if (this.to_date.length > 0) {
                queryString += `&to_date=${this.to_date}`;
            }
            if (this.agent_status > 0) {
                const statuses = {
                    1: "pending",
                    2: "in_progress",
                    3: "done",
                };
                queryString += `&agent_status=${statuses[this.agent_status]}`;
            }
            console.log(queryString);
            this.$httpAdmin
                .get(`/test-drives/requests?${queryString}`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.items = res.data.data.data;
                    console.log({ items: this.items });
                    this.totalItems = Math.ceil(
                        res.data.data.total / this.itemsPerPage
                    );
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        filter() {
            this.getData();
        },
    },
    mounted() {
        this.isMounted = true;
        this.getData();

        socket.on("new-request", (data) => {
            if (data.type === "NEW_TEST_DRIVE") {
                this.getData();
            }
        });
    },
};
</script>

<style lang="scss">
#data-list-list-view {
    .vs-con-table {
        /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
        @media (max-width: 689px) {
            .vs-table--search {
                margin-left: 0;
                max-width: unset;
                width: 100%;

                .vs-table--search-input {
                    width: 100%;
                }
            }
        }

        @media (max-width: 461px) {
            .items-per-page-handler {
                display: none;
            }
        }

        @media (max-width: 341px) {
            .data-list-btn-container {
                width: 100%;

                .dd-actions,
                .btn-add-new {
                    width: 100%;
                    margin-right: 0 !important;
                }
            }
        }

        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap;
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            > span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    & + i {
                        left: 1rem;
                    }

                    &:focus + i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0 1rem;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

                td {
                    padding: 20px;

                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }

                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }
                }

                td.td-check {
                    padding: 20px !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }

            th.td-check {
                padding: 0 15px !important;
            }

            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
</style>
