<template>
    <vs-td>
         <p><div v-html="data"> </div></p>
    </vs-td>
</template>

<script>
    export default {
        name: 'html-row',
        props: ["data"]
    };
</script>

<style scoped>

</style>
