<template>
  <div class="flex flex-wrap-reverse items-center">
    <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="$emit('click')">
      Add New
    </vs-button>
  </div>
</template>

<script>
  export default {
    name: 'add-new-button',
    //props: ["data"]
    // methods: {
    //   getOrderStatusColor(status) {
    //     if (status == 0) return 'warning';
    //     if (status == 1) return 'success';
    //     return 'primary';
    //   },
    //   getOrderStatusState(status) {
    //     if (status == 0) return 'Unavailable';
    //     if (status == 1) return 'Available';
    //     return 'Unknown';
    //   },
    // }
  };
</script>

<style scoped>

</style>
