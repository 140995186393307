<template>
    <vs-td>
        <vs-chip :color="getOrderStatusColor(data)" class="product-order-status">{{data}}
        </vs-chip>
    </vs-td>
</template>

<script>
    export default {
        name: 'tag-row',
        props: ["data"],
        methods: {
            getOrderStatusColor(data) {
                if (data === 'Active') {
                    return "success";
                } else if (data === 'Cancelled') {
                    return 'warning';
                }
                return 'primary';
            },
        }
    };
</script>

<style scoped>

</style>
