<template>
    <div>
        <vs-td style="height: 70px; vertical-align: middle; width: 130px">
            <div>
                <feather-icon
                    v-if="show"
                    icon="EyeIcon"
                    svgClasses="w-6 mt-1 h-6 mr-2 hover:text-success stroke-current"
                    @click.stop="$emit('onShowClicked')"
                />
                <feather-icon
                    v-if="copy"
                    icon="CopyIcon"
                    svgClasses="w-6  mt-1 h-6 mr-2 hover:text-success stroke-current"
                    @click.stop="$emit('onCopyClicked')"
                />
                <feather-icon
                    v-if="editable"
                    icon="EditIcon"
                    svgClasses="w-6  mt-1 h-6 mr-2 hover:text-warning stroke-current"
                    @click.stop="$emit('onEditClicked')"
                />
                <feather-icon
                    v-if="canDelete"
                    icon="TrashIcon"
                    svgClasses="w-6 h-6 hover:text-danger stroke-current"
                    @click.stop="popupActivo = true"
                />
            </div>
        </vs-td>

        <vs-popup
            class="holamundo"
            title="Delete Item"
            :active.sync="popupActivo"
        >
            <div>
                <h3 class="text-center text-danger">Are You Sure?</h3>
                <div class="text-center mt-8 sure-message">
                    You are about to delete this item!
                </div>

                <div class="float-right d-flex mt-8">
                    <vs-button
                        color="success"
                        class="mr-2"
                        @click="popupActivo = false"
                    >
                        Cancel
                    </vs-button>
                    <vs-button color="danger" class="ml-2" @click="deleteItem">
                        Delete
                    </vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import store2 from "store2";

export default {
    name: "actions-row",
    props: {
        show: {
            type: Boolean,
            default: () => false,
        },
        deletable: {
            type: Boolean,
            default: () => true,
        },
        editable: {
            type: Boolean,
            default: () => true,
        },
        copy: {
            type: Boolean,
            default: () => false,
        },
        rowheight: {
            type: Number,
            default: () => 130,
        },
        permissionName: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            popupActivo: false,
            canDelete: this.deletable,
        };
    },
    methods: {
        deleteItem() {
            this.popupActivo = false;
            this.$emit("onDeleteClicked");
        },
    },
    mounted() {
        if (store2.get("admin") === "Super Admin") {
            return;
        }
        if (this.permissionName) {
            const permissions = JSON.parse(store2.get("permissions"));
            this.canDelete = permissions.find(
                (permission) => permission.title === this.permissionName
            );
        }
    },
};
</script>
